<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			 return {}
		},
		computed: {

		},
		async mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshMeta()
		},
		methods: {
			refreshMeta() {
				Gen.rest(this.$route.path, { metainfo: true }, (err, resp) => {
					if (err) return console.error(err);
		
			        $("[rel=alternate]").remove()
					$("[rel=canonical]").remove()
					
					if(this.$route.name == "Index"){
						$("head").append('<link rel="canonical" href="https://www.sdi-alhidayah.sch.id">')
						$("[name='robots']").attr('content','index,follow')
						$("[name='googlebot']").attr('content','index,follow')
					} 
					else if(this.$route.name && this.$route.name !== "404"){
						$("head").append(`<link rel="alternate" href="${window.location.href}" hreflang="id-id" />`)
						$("head").append(`<link rel="alternate" href="${window.location.href}" hreflang="x-default" />`)  
						$("[name='robots']").attr('content','index,follow')
						$("[name='googlebot']").attr('content','index,follow')
					}
					else{
						$("[name='robots']").attr('content','noindex,nofollow')
						$("[name='googlebot']").attr('content','noindex,nofollow')
					}
					
					$("title").text(resp.meta_title);
					$("meta[name='keywords']").attr('content', resp.meta_keyword)
					$("meta[name='description']").attr('content', resp.meta_desc)
					$("meta[name='author']").attr("content", 'SDI Al Hidayah');

					// OG
					$("meta[property='og:image']").attr('content', resp.ss_img)
					$("meta[property='og:title']").attr('content', resp.meta_title)
					$("meta[property='og:keywords']").attr('content', resp.meta_keyword)
					$("meta[property='og:description']").attr('content', resp.meta_desc)
					$("meta[property='og:url']").attr('content', resp.ss_url)
					
					// Twitter
					$("meta[name='twitter:image']").attr('content', resp.ss_img)
					$("meta[name='twitter:title']").attr('content', resp.meta_title)
					$("meta[name='twitter:description']").attr('content', resp.meta_desc)
					$("meta[name='twitter:url']").attr('content', resp.ss_url)
					this.$root.meta = resp;
				});
			},
		},
		watch: {
		    $route() {
				this.refreshMeta()
			}
		}
	};
</script>
<template>
  <div>
    <section id="content">
			<div class="content-wrap">
				<section class="section pt-sm">
					<div class="container">
						<div class="notfound">
							<div class="row justify-content-center">
								<div class="col-auto">
									<img itemprop="image" :src="assets('fo_images', '404.svg')" alt="Halaman Tidak Ditemukan" title="Halaman Tidak Ditemukan" class="notfound__img">
								</div>
								<div class="w-100"></div>
								<div class="col-lg-6 col-md-8">
									<div class="notfound-meta text-center">
										<h2 itemprop="alternativeHeadline" class="notfound-meta__title">Kami Tidak Dapat Menemukan Halaman yang Anda Cari</h2>
										<router-link :to="{name: 'Index'}" class="cta cta--primary">Kembali ke Beranda</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</section><!-- #content end -->
  </div>
</template>